<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-md-3">
        <h3 class="text-secondary">Category</h3>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a
              :href="$router.resolve({ name: 'BlogListDynamic' }).href"
              class="nav-link bg-primary text-white"
            >
              All categories
            </a>
          </li>
          <li class="nav-item" v-for="i in $data.categories.length" :key="i">
            <a
              :href="
                $router.resolve({
                  name: 'BlogCategory',
                  params: { slug: $data.categories[i - 1].slug },
                }).href
              "
              :class="{
                'nav-link': true,
              }"
            >
              {{ $data.categories[i - 1].name }}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-md-9">
        <h3 class="text-secondary" v-if="$data.blogs.length > 0">Blogs list</h3>
        <h3 class="text-secondary" v-else>There's no data</h3>

        <div class="card mb-3" v-for="i in $data.blogs.length" :key="i">
          <div class="row g-0">
            <div class="col-md-4">
              <router-link :to="{ name: 'BlogDetail', params: { slug: $data.blogs[i - 1].slug } }">
                <img
                  :src="'/uploads/blogs/covers/medium/' + $data.blogs[i - 1].image"
                  :alt="$data.blogs[i - 1].title"
                  class="w-100 p-1"
                />
              </router-link>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <router-link
                  :to="{ name: 'BlogDetail', params: { slug: $data.blogs[i - 1].slug } }"
                >
                  <h5 class="card-title">
                    {{ $data.blogs[i - 1].title }}
                  </h5>
                </router-link>
                <p class="card-text text-justify">
                  {{ $data.blogs[i - 1].short_content }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogListDynamic',
  data: function () {
    return {
      blogs: [],
      categories: [],
    };
  },
  async created() {
    document.title = 'Trepr - Blogs';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'If you want to know more about earning while travel or looking for a travel companionship or travel news or sending packages; we have it all covered.'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr,Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );

    let currentUrl = window.location.pathname;
    console.log('currentUrl ', currentUrl);
    let apiUrl = process.env.VUE_APP_API_URL;

    let data = {
      cat_slug: '',
      page: 1,
      item_per_page: 15,
    };

    const formData = Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
    let promiseBlogs = fetch(apiUrl + 'blogs/get-all', {
      method: 'POST',
      headers: {
        // 'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
      },
      body: formData,
    }).then((response) => response.json());

    let promiseCagegories = fetch(apiUrl + 'blogs/get-categories', {
      method: 'POST',
      headers: {
        // 'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
      },
    }).then((response) => response.json());

    let self = this;
    // console.log("state $store ", this.$store.state)

    await Promise.all([promiseBlogs, promiseCagegories])
      .then((resData) => {
        // console.log(resData);
        if (resData[0].status == 'error') {
          alert('error while geting data ' + resData[0].message);
          return;
        }
        self.$data.blogs = resData[0].blogs;
        if (resData[1].status == 'error') {
          alert('error while geting data ' + resData[1].message);
          return;
        }
        self.$data.categories = resData[1].categories;
      })
      .catch((err) => {
        console.error('Error:', err);
      });
  },
};
</script>

<style scoped></style>
